
export function getEnvironment() {
  const hostname = window.location.hostname;
  var environment = 'LOCAL';
  if (hostname === 'blog.mattmiling.com') {
    environment = 'PROD';
  }
  return environment;
}

export function getApiUrl() {
  const env = getEnvironment();

  var apiUrl = 'http://localhost:5000';
  if (env === 'PROD') {
    apiUrl = 'https://blog-api.mattmiling.com';
  }

  return apiUrl;
}