import { getApiUrl } from "../hostUtil";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { formatDate, formatMonth } from "../dateUtil";
import { isSessionValid, getSession } from "../services/TokenService";
import Login from "./Login";

export default function Admin() {
  const [entryCounts, setEntryCounts] = useState([]);
  const [entryMonthCounts, setEntryMonthCounts] = useState([]);
  const [entryTagData, setEntryTagData] = useState({});

  const session = getSession();

  useEffect(() => {
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Content-Type": "application/json"
      }
    });

    function setCounts(entries) {
      // Map of date:count entries
      var entryCounts = {};
      var entryMonthCounts = {};
      var entryTagCounts = {};

      for (let entry of entries) {
        const epochTime = entry.created_ts;
        var d = new Date(0);
        d.setUTCSeconds(epochTime);
        const dateString = formatDate(d);
        const monthString = formatMonth(d);

        if (dateString in entryCounts) {
          entryCounts[dateString] = entryCounts[dateString] + 1;
        } else {
          entryCounts[dateString] = 1;
        }

        if (monthString in entryMonthCounts) {
          entryMonthCounts[monthString] = entryMonthCounts[monthString] + 1;
        } else {
          entryMonthCounts[monthString] = 1;
        }

        const tags = entry.tags;
        for (let tag of tags) {
          if (tag in entryTagCounts) {
            entryTagCounts[tag] = entryTagCounts[tag] + 1;
          } else {
            entryTagCounts[tag] = 1;
          }
        }
      }

      // Normalizing to List of value:count and day:date entries
      var entryCountPairs = [];
      for (const [key, value] of Object.entries(entryCounts)) {
        entryCountPairs.push({
          "value": value,
          "day": key
        })
      }

      setEntryCounts(entryCountPairs);

      // Normalizing to List of value:count and day:date entries
      var entryMonthCountPairs = [];
      for (const [key, value] of Object.entries(entryMonthCounts)) {
        entryMonthCountPairs.push({
          "id": key,
          "label": key,
          "value": value
        })
      }

      setEntryMonthCounts(entryMonthCountPairs);

      // Normalizing the map of circle values
      const tagData = {
        "name": "tags",
        "color": "hsl(38, 70%, 50%)",
        "children": []
      };
      for (const [key, value] of Object.entries(entryTagCounts)) {
        tagData.children.push({
          "name": key,
          "color": "hsl(109, 70%, 50%)",
          "loc": value
        });
      }
      setEntryTagData(tagData);
    }

    async function fetchEntries() {
      const response = await request.get('/entry');
      const entries = response.data.entries;

      setCounts(entries);
    }
    fetchEntries();
  }, []);

  if (!isSessionValid(session)) {
    return (
      <Login redirect="/admin" />
    )
  }

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Body style={{height:300}}>
              <Card.Title>Blog Entry Counts by Month</Card.Title>
              <ResponsivePie
                data={entryMonthCounts}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                defs={[
                  {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true
                  },
                  {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                  }
              ]}
              legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
                />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body style={{height:300}}>
              <Card.Title>Blog Entry Tags/Counts</Card.Title>
              <ResponsiveCirclePacking
                data={entryTagData}
                leavesOnly
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                id="name"
                value="loc"
                colors={{ scheme: 'nivo' }}
                childColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            0.4
                        ]
                    ]
                }}
                padding={4}
                colorBy='id'
                enableLabels={true}
                labelsFilter={function(n){return 1===n.node.depth}}
                labelsSkipRadius={10}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.5
                        ]
                    ]
                }}
                defs={[
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'none',
                        color: 'inherit',
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8
                    }
                ]}
                fill={[
                    {
                        match: {
                            depth: 2
                        },
                        id: 'lines'
                    }
                ]}
            />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body style={{height: 500}}>
              <Card.Title>Blog Entry Counts By Day</Card.Title>
              <ResponsiveCalendar 
                data={entryCounts}
                from="2022-02-01"
                to="2023-01-31"
                emptyColor="#eeeeee"
                colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'row',
                        translateY: 36,
                        itemCount: 4,
                        itemWidth: 42,
                        itemHeight: 36,
                        itemsSpacing: 14,
                        itemDirection: 'right-to-left'
                    }
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
}