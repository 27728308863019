import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, {useState, useEffect} from "react";
import { getApiUrl } from '../hostUtil';
import EntryCard from "./EntryCard";
import { useParams } from 'react-router-dom';
import { Pagination } from "react-bootstrap";
import PlaceholderCard from "./PlaceholderCard";

export default function Entries() {
  const [entries, setEntries] = useState([]);
  const [numPages, setNumPages ] = useState(0);
  let { pageNumber } = useParams();
  const limit = 3;
  if (!pageNumber) {
    pageNumber = 1;
  } else {
    pageNumber = parseInt(pageNumber);
  }

  function calculateNumPages(count) {
    return count / limit + (count % limit === 0 ? 0 : 1);
  }

  useEffect(() => {
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Content-Type": "application/json"
      }
    });

    async function fetchEntries() {
      const offset = (pageNumber - 1)*limit;
      const response = await request.get(`/entry?limit=${limit}&offset=${offset}&order=desc`);
      const entriesResponse = response.data
      setNumPages(calculateNumPages(entriesResponse.total_count));
      const entries = entriesResponse.entries;
      entries.sort((a, b) => {
        if (a.created_ts < b.created_ts)
          return -1;
        else if (a.created_ts > b.created_ts)
          return 1;
        else
          return 0; 
        }).reverse();

      setEntries(entries);
    }
    fetchEntries();
  }, [pageNumber]);

  function populateEntries(entries) {
    if (entries.length > 0) {
      return (<>
        {
          entries.map(function(entry, i){
          return (
            <Row key={entry.id}>
              <Col>
                <EntryCard entry={entry} />
              </Col>
            </Row>
          )
          }
          )
        }
      </>)
    } else {
      const numberEntries = [...Array(3).keys()];
      return (<>
        {
          numberEntries.map(function(i){
            return (
              <Row key={i}>
                <Col>
                  <PlaceholderCard />
                </Col>
              </Row>
            )
          })
        }
      </>)
    }
  }

  return (
    <Container className="align-middle">
      { populateEntries(entries) }
      <Row>
        <Col>
          <Pagination className="justify-content-center">
            <Pagination.First href='/entries/1' />
            { pageNumber > 1 &&
              <Pagination.Prev href={`/entries/${pageNumber - 1}`}/>
            }
            <Pagination.Item>{pageNumber}</Pagination.Item>
            { pageNumber < numPages &&
              <Pagination.Next href={`/entries/${pageNumber + 1}`}/>
            }
            <Pagination.Last href={`/entries/${numPages}`}/>
          </Pagination>
        </Col>
      </Row>

    </Container>
  )
}