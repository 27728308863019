import Card from "react-bootstrap/Card";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { clearSession, getSession } from "../services/TokenService";

export default function Logout() {
  const session = getSession();
  var username = "";

  if (session) {
    username = session.username;
  }

  async function doLogout() {
    await new Promise(r => setTimeout(r, 3000));

    clearSession();

    // Redirect to frontpage.
    window.location.replace('/');
  }

  doLogout();

  return (
    <Container className="align-middle">
      <Row className="justify-content-md-center align-middle">
        <Col offset="4" md="4" className="align-middle">
          <Card>
            <Card.Body>
              <Card.Title>Logging out user {username}</Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}