import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useLoaderData} from "react-router-dom";
import {getApiUrl} from '../hostUtil';
import EntryCard from "./EntryCard";

export async function loader({params}) {
  const entryId = params.entryId;
  const request = axios.create({
    baseURL: getApiUrl(),
    headers: {
      "Content-Type": "application/json"
    }
  });

  const response = await request.get(`/entry/${entryId}`);
  return response.data;
}

export default function Entry() {
  const entry = useLoaderData();

  return (
    <Container>
      <Row key={entry.id}>
        <Col>
          <EntryCard entry={entry} />
        </Col>
      </Row>
    </Container>
  );
}