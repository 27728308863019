import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import {formatEpochToLocal} from '../dateUtil';
import parse from 'html-react-parser';

export default function EntryCard(props) {
  const entry = props.entry;

  return (
    <Card>
      <Card.Body>
        <Link to={"/entry/" + entry.id} className="bloglink-heavy">
          <Card.Title><h1>{entry.title}</h1></Card.Title>
        </Link>
        <Card.Text className="text-muted">{formatEpochToLocal(entry.created_ts)}</Card.Text>
        <Card.Text>{parse(entry.body)}</Card.Text>
        { entry.tags.map(function(tag, i) {
            return ( <Badge bg="secondary">{tag}</Badge>)
          })
        }
      </Card.Body>
    </Card>
  )
}