import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

export default function PlaceholderCard() {
  return (
    <Card>
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={2} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      </Card.Body>
    </Card>
  )
}