import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import axios from 'axios';
import { getApiUrl } from '../hostUtil';
import { getSession } from '../services/TokenService';

export default function AddPost() {
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [tags, setTags] = useState();

  const [formDisabled, setFormDisabled] = useState(false);

  async function submitEntry(event) {
    event.preventDefault();
    setFormDisabled(true);

    const tagsList = tags.split(',');
    for (var i = 0; i < tagsList.length; i++) {
      tagsList[i] = tagsList[i].trim();
    }
    const token = getSession().token;
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      responseType: "json"
    });

    const response = await request.post('/entry', {
      "title": title,
      "body": body,
      "tags": tagsList
    });

    const id = response.data['id'];

    // Redirect to new Blog Entry.
    window.location.replace(`/entry/${id}`);
  }

  return (
    <Container>
      <h2>New Entry</h2>
      <Form>
        <fieldset disabled={formDisabled}>
        <Form.Group className="mb-3" controlId="entryTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control type="input" onChange={e => setTitle(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="entryBody">
          <Form.Label>Body</Form.Label>
          <Form.Control as="textarea" rows={20} onChange={e => setBody(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="entryTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control type="input" onChange={e => setTags(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={submitEntry} >Submit</Button>
        </fieldset>
      </Form>
    </Container>
  );
}