
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import { useEffect, useState } from 'react';
import { getApiUrl } from '../hostUtil';
import { getSession } from '../services/TokenService';

export default function EditPost() {
  const [entries, setEntries] = useState([]);
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();

  const [formDisabled, setFormDisabled] = useState(true);

  // An array of tags.
  const [tags, setTags] = useState();
  const [entryMap, setEntryMap] = useState();

  useEffect(() => {
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    });
  
    async function fetchEntries() {
      const response = await request.get('/entry');
      const entries = response.data.entries;
      entries.sort((a, b) => {
        if (a.created_ts < b.created_ts)
          return -1;
        else if (a.created_ts > b.created_ts)
          return 1;
        else
          return 0; 
        }).reverse();

      if (entries.length > 0) {
        setEntries(entries);

        const newEntryMap = [];
        entries.map((entry) => newEntryMap[entry.id] = entry );
        setEntryMap(newEntryMap);

        const activeEntry = entries[0];
        setId(activeEntry.id);
        setTitle(activeEntry.title);
        setBody(activeEntry.body);
        setTags(activeEntry.tags);
        setFormDisabled(false);
      }
    }
    fetchEntries();
  }, []);

  function updateActiveEditEntry(event) {
    const id = event.target.value;
    setId(id);
    const entry = entryMap[id];
    setTitle(entry.title);
    setBody(entry.body);
    setTags(entry.tags);
  }

  async function submitEntry(event) {
    event.preventDefault();
    setFormDisabled(true);

    const token = getSession().token;
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      responseType: "json"
    });

    request.put(`/entry/${id}`, {
      "title": title,
      "body": body,
      "tags": tags
    }).then(function(response) {
      // Redirect to the edited Blog Entry.
      window.location.replace(`/entry/${id}`);
    }).catch(function(error) {
      console.log(`Error occurred attempting to submit item: ${error}`);
      setFormDisabled(false);
    });
  }

  return (
    <Container>
      <h2>Edit Entry</h2>
      <Form>
        <fieldset disabled={formDisabled}>
          <Form.Group className="mb-3" controlId="entryTitle">
            <Form.Label>Select Entry</Form.Label>
            <Form.Select aria-label="Select entry to edit" onChange={updateActiveEditEntry}>
              {entries.map(function(entry, i){
                return(<option key={entry.id} value={entry.id}>{entry.title}</option>)
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="entryTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control type="input" value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="entryBody">
            <Form.Label>Body</Form.Label>
            <Form.Control as="textarea" value={body} rows={20} onChange={e => setBody(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="entryTags">
            <Form.Label>Tags (comma separated)</Form.Label>
            <Form.Control type="input" value={tags} onChange={e => setTags(e.target.value.split(',').map((entry) => entry.trim()))} />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={submitEntry} >Submit</Button>
        </fieldset>
      </Form>
    </Container>
  );
}