import Card from 'react-bootstrap/Card';

export default function About() {
  return (
    <Card>
      <Card.Body>
          <Card.Title>About this Blog</Card.Title>
          <Card.Body>
            I'm a software developer of over 20 years with a passion for software, athletics, nutrition, and sports.
            This blog primarily touches on my journeys and musings on full stack cloud development, predominantly in AWS.
            I may also sneak in a few entries detailing my experience in fitness and nutrition as I'm a firm believe in 
            solid body, solid mind.  
          </Card.Body>
          <Card.Body>
            This site is a full stack application.  It consists of:
            <ul>
              <li>Frontend written in Javascript using React and React Bootstrap, hosted in AWS using Route53, Cloudfront, and S3,</li>
              <li>Backend written in Python using AWS SAM to deploy an API Gateway and a Lambda, and</li>
              <li>Database using DynamoDB.</li>
            </ul>
          </Card.Body>
          <Card.Body class="card-center">
            <a href="/blog-deployment.png">
              <img class="img-fluid" alt="Blog deployment diagram" src="/blog-deployment.png" width="400px"></img>
            </a>
          </Card.Body>
          <Card.Body>
            For any questions/concerns please reach out!
            <ul>
              <li>admin[at]mattmiling[dot]com</li>
              <li>LinkedIn: <a class="bloglink" href="https://linkedin.com/in/mattmiling">mattmiling</a></li>
            </ul>
          </Card.Body>
      </Card.Body>
    </Card>
  );
}