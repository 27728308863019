
export function getSession() {
  const blogSessionString = sessionStorage.getItem('blog-session');
  var blogSession;

  if (blogSessionString) {
    blogSession = JSON.parse(blogSessionString);
  }

  return blogSession;
}

// Expriation time, which is seconds in a day times 1000 milliseconds/second
const EXPIRATION_INTERVAL_MILLIS = 86400*1000;

export function setSession(username, token) {
  const creationTimestamp = Date.now();
  const expirationTimestamp = Date.now() + EXPIRATION_INTERVAL_MILLIS;
  const blogSession = {
    'username': username,
    'token': token,
    'creationTimestamp': creationTimestamp,
    'expirationTimestamp': expirationTimestamp
  }

  sessionStorage.setItem('blog-session', JSON.stringify(blogSession));
}

export function isSessionValid(session) {
  const currentTimestamp = Date.now();
  if (session && currentTimestamp < session.expirationTimestamp) {
    return true;
  }
  return false;
}

export function clearSession() {
  sessionStorage.removeItem('blog-session');
}