import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { getSession, isSessionValid } from '../services/TokenService';

function Navigation() {

  function populateDropdown() {
    if (!isSessionValid(getSession())) {
      return (
        <>
          <NavDropdown.Item href="/login">login</NavDropdown.Item>
        </>
      );
    }

    return (
      <>
        <NavDropdown.Item href="/admin">stats</NavDropdown.Item>
        <NavDropdown.Item href="/add-post">add post</NavDropdown.Item>
        <NavDropdown.Item href="/edit-post">edit post</NavDropdown.Item>
        <NavDropdown.Item href="/logout">logout</NavDropdown.Item>
      </>
    );
  }

  return (
    <Container>
      <Navbar sticky="top">
        <Navbar.Brand href="/">
          <img alt="mattmiling::blog" width="60" height="60" src="/logo192.png" />{' '}
          mattmiling::blog
        </Navbar.Brand>
        <Navbar.Collapse id="blog-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/about">about</Nav.Link>
            <NavDropdown title="admin" id="blog-nav-dropdown">
              {populateDropdown()}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
     </Navbar>
    </Container>
  );
}

export default Navigation;
