import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import axios from 'axios';
import { getApiUrl } from '../hostUtil';
import { setSession } from '../services/TokenService';

export default function Login(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [formDisabled, setFormDisabled] = useState(false);

  async function loginSubmitHandler(e) {
    e.preventDefault();
    setFormDisabled(true);
    const request = axios.create({
      baseURL: getApiUrl(),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      responseType: "json"
    });

    const response = await request.post('/login', {
      'username': username,
      'password': password
    });

    const token = response.data['token'];
    setSession(username, token);

    // Redirect to frontpage.
    window.location.replace('/');
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col offset="4" md="4">
          <Card>
            <Card.Body>
              <Card.Title>Login</Card.Title>
              <Form>
                <fieldset disabled={formDisabled}>
                <Form.Group className="mb-3" controlId="formBasicUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control placeholder="Enter username" onChange={e => setUsername(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={loginSubmitHandler}>
                  Submit
                </Button>
                </fieldset>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}