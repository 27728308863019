
export function formatDate(d) {
  return `${d.getFullYear().toString()}-${(d.getMonth()+1).toString().padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
}

export function formatMonth(d) {
  return `${d.getFullYear().toString()}-${(d.getMonth()+1).toString().padStart(2, "0")}`;
}

function formatTime(d) {
  const dayPeriod = d.getHours() < 12 ? "am" : "pm";
  const absHour = d.getHours();
  const hour = absHour > 12 ? absHour - 12 : absHour;
  return `${hour}:${d.getMinutes().toString().padStart(2, "0")}${dayPeriod}`; 

}

export function formatEpochToLocal(epochTime) {
  var d = new Date(0);
  d.setUTCSeconds(epochTime);
  return `${formatDate(d)} ${formatTime(d)}`;
}
