import Navigation from './components/Navigation.jsx';
import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';

export default function App() {
  return (
    <Container className='app-pane'>
      <Navigation />
      <Outlet />
    </Container>
  );
}