import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import About from './components/About';
import AddPost from './components/AddPost';
import Admin from './components/Admin';
import EditPost from './components/EditPost';
import Entries from './components/Entries';
import Entry, {
  loader as entryLoader
} from './components/Entry';
import Login from './components/Login';

import 'bootstrap/dist/css/bootstrap.css';
import Logout from './components/Logout';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Entries />
      },
      {
        path: "entries/:pageNumber",
        element: <Entries />
      },
      {
        path: "entry/:entryId",
        element: <Entry />,
        loader: entryLoader
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "admin",
        element: <Admin />
      },
      {
        path: "add-post",
        element: <AddPost />
      },
      {
        path: "edit-post",
        element: <EditPost />
      },
      {
        path: "login",
        element: <Login />
      },
      {
        path: "logout",
        element: <Logout />
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
